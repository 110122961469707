@import '../common/Variables.scss';

.react-tabs__tab--selected {
  background: #fff;
  border-color: #aaa;
  color: var(--eds-color-text-default) !important;
  border-bottom: 2px solid !important;
  padding-bottom: var(--eds-space-8) !important;
}

.react-tabs__tab:hover {
  color: var(--eds-color-text-default);
}

.react-tabs__tab {
  color: var(--eds-color-text-secondary);
  display: inline-block;
  position: relative;
  list-style: none;
  margin: 6px 12px;
  cursor: pointer;
  font: var(--eds-type-subtitle-1);
  transition: border-bottom-color 0.25s, color 0.25s;
}

#draft-toggle {
  background-color: var($draft-color);
  border: 1.5px solid var($draft-color);
  color: var(--eds-color-white);
}

#factory-toggle {
  background-color: var($factory-color);
  border: 1.5px solid var($factory-color);
  color: var(--eds-color-white);
}

#sourcing-toggle {
  background-color: var($sourcing-color);
  border: 1.5px solid var($sourcing-color);
  color: var(--eds-color-white);
}

#cancelled-toggle {
  background-color: var($cancel-color);
  border: 1.5px solid var($cancel-color);
  color: var(--eds-color-white);
}

#confirmed-toggle {
  background-color: var($confirm-color);
  border: 1.5px solid var($confirm-color);
  color: var(--eds-color-white);
}


.view-state-buttons:first-child {
  border-radius: 30px 0px 0px 30px;
  margin-right: 8px;
}

.view-state-buttons:nth-child(2) {
  border-radius: 0px;
  margin-right: 8px;
}

.view-state-buttons:only-child {
  border-radius: 30px !important;
  margin-right: 8px !important;
}

.view-state-buttons:last-of-type {
  border-radius: 0px 30px 30px 0px;
  margin-right: 8px;
}

.eds-button__ripple {
  transition: none;
}